import $ from 'jquery';

$(window).on('load', function (){
	console.log(document.styleSheets);
	let css = undefined;
	for (var i = 0; i < document.styleSheets.length; i++) {
		if (document.styleSheets[i].title == 'keyframes') {
			css = document.styleSheets[i];
			break;
		}
	}

	var tweens = {};
	var i = 0;
	$(".frame").each(function() {
		var animation_name = $(this).attr('data-animation');
		var key_name = animation_name;
		if (key_name in tweens) {
			key_name += '-' + 'dupe';
			i++;
		}
		var animation = false;
		var i = 0;
		while (!animation) {
			if (i > css.rules.length - 1) {
				console.log('Animation ' + animation_name + ' was not found.')
				break;
			}
			if (css.rules[i].name == animation_name) {
				animation = convert_rule(css.rules[i].cssText);		
			}
			i++
		}
		tweens[key_name] = {
		 element: $(this),
		 animations: animation
		};
	})
	init_tweens(tweens);
})


function init_tweens(tweens) {
	// set element scroll points
	for (var frame in tweens) {
		tweens[frame].start = tweens[frame].element.offset().top;
		tweens[frame].end = tweens[frame].start + tweens[frame].element.outerHeight();
		tweens[frame].image = tweens[frame].element.find('.frame-image').first();
  }
	
	var wheight = $(window).height();
	var ypos;
	$(window).scroll(function() {
		update_tweens();
	})

	function update_tweens() {
		ypos = $(window).scrollTop();
		var updates = []; // create an array of transforms to be made, so we can make them all at once
		// for each tween
		for (var frame in tweens) {
			var start = tweens[frame].start;
			var end = tweens[frame].end;
			// if we're getting close, prep with will-change CSS
			if (ypos > start - wheight - 400 && ypos < end + 400) {
				tweens[frame].element.addClass('will-change');
			} else {
				tweens[frame].element.removeClass('will-change');
			}
			// if in view
			if (ypos > tweens[frame].start - wheight && ypos < tweens[frame].end) {
				tweens[frame].element.addClass('in-view seen');
				var newTween = {};
				newTween.frame = tweens[frame];
				newTween.transforms = {};
				var current_pos = (100 * (ypos + wheight - tweens[frame].start) / (tweens[frame].end - tweens[frame].start + wheight));
				var animations = tweens[frame].animations;
				// for each property..
				for (var index in animations) {
					// console.log(animations[index]);
					var property = index;
					// find the top and bottom time points for each property..
					for (var n = 0; n < animations[index].length; n++) {
						// console.log(n, animations[index].length);
						if (animations[index][n + 1].timeline >= current_pos) {
							var bottom = animations[index][n];
							break;
						}
					}
					for (var n = animations[index].length -1; n > 0; n--) {
						if (animations[index][n - 1].timeline < current_pos) {
							var top = animations[index][n];
							break;
						}
					}
					var top_bottom_pos = (current_pos - bottom.timeline) / (top.timeline - bottom.timeline);
					var value =  (+bottom.value + (top_bottom_pos * (top.value - bottom.value)));
					let value_string = bottom.prefix + round(value, 4) + bottom.suffix;

					// add the property and value to the new tween
					if (property == "transform" || property == "-webkit-transform") value_string = value_string + "translate3d(0, 0, 0)";
					newTween.transforms[property] = value_string;
					//tweens[frame].image.css(property, value_string);
					tweens[frame].element.attr('data-percentage', current_pos);
				}
				updates.push(newTween);
			} else {
				tweens[frame].element.removeClass('in-view');
			}
		}
		// make the transforms
		for (var i = 0; i < updates.length; i++) {
			var element = updates[i].frame.image[0];
			var transforms = updates[i].transforms;
			var cssString = "";
			for (var property in transforms) {
				cssString += property + ': ' + transforms[property] + '; ';
			}
			element.style.cssText = cssString;
		}
	}
}

function strip_empties(obj) {
	for (var i in obj) {
		if (obj[i] === null || obj[i] === undefined || obj[i] == "") {
			delete obj[i]
		}
	}
	return obj;
}


function round(value, decimals) {
    return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
}


round(1.005, 2); // 1.01
// TO DO:: 
//   - Add 100% items for all undeclared!! IMPORTANT
//   - if no unit is found (0% { top: 0 } ), look for other values to find it (100% { top: 5rem; })  			

function convert_rule(string) {

	var regexes = [
		new RegExp("\n", 'g'), // strip newlines
		//new RegExp('-webkit-keyframes(.*){', 'g'), // strip webkit
		new RegExp("@(-webkit-)?keyframes(.*?){"), // strip beginning of container
		new RegExp("}(?!.*})"), // strip final bracket
		new RegExp(" ", 'g'), // strip spaces
	]
	for (let i = 0; i < regexes.length; i++) {
		string = string.replace(regexes[i], "");
	}


	//var percentage_handler = new RegExp("(^|})(%)({|$})", 'g');
	string = string.replace(new RegExp(';', 'g'), ',')
								 .replace(new RegExp("(^|})([0-9\.]+)(%)({)", 'g'), "$1$2:$4"); // prep JSON-ish string
	var json_object_names = new RegExp("({|,|}|^)(?:\s*)(?:')?([-A-Za-z0-9_$\.][-A-Za-z0-9_ \-\.$]*)(?:')?(?:\s*):", 'g');
	var json_object_values = new RegExp("(:)([A-Za-z0-9\-_$\.][A-Za-z0-9_ \-\.$]*)(,)", 'g')
	
	string = string
						.replace(json_object_names, "$1\"$2\":")
						.replace(json_object_values, "$1\"$2\",")
						.replace(new RegExp(",}", 'g'), "},")
						.replace(new RegExp("},$"), "}");						
	string = "{" + string + "}";
	const converted_object = $.parseJSON(string);

	var tweens = {};
	var value_regex = new RegExp('([0-9.-]+)', 'g');
	
	for(var percentage_index in converted_object) {
		// each frame percentage indicator is now a property of the converted_object.
		// for each one of those:
		for (var property_index in converted_object[percentage_index]) {
			if (!tweens[property_index]) tweens[property_index] = [];
			var full_value = converted_object[percentage_index][property_index];

			// parse the full value into three parts: prefix, value, suffix: 'translateX(1.1rem)' -> ['translateX(', '1.1', 'rem)'];
			// do this by finding the value (as an integer or decimal), and using its index to substr 
			var split_arr = full_value.split(value_regex);
			var property_position = {
				'timeline': percentage_index,
				'prefix': split_arr[0],
				'value': split_arr[1],
				'suffix': split_arr[2]
			};
			tweens[property_index].push(property_position);

			// tweens[property_index] = converted_object[percentage_index][property_index]
		}
	}
	return tweens;
}

