import $ from 'jquery';

$(window).on('load', function() {
	var body = $("body");
	$(document).scrollTop(0);
	body.scrollTop(0);
	setTimeout(function() {
		body.addClass('loaded');
		body.scrollTop(0);
		setTimeout(function(){
			body.addClass('scrollable')
		}, 5500)
	}, 2000);
})