import $ from 'jquery';
import axios from 'axios';

function showSuccess() {
	$('.mailing-list').addClass('success');
}


$(document).ready(function() {

	var ajaxRoot = (window.location.hostname === 'bwana.dev') ? "" : '/bwana';

	$("#mc_submit").click(function() {
		var button = $(this);
		var data = {};
		data.email_address = $('#mc_input').val();

		// email regex pattern
		var pattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
		if (data.email_address.length == 0) {
			$('.mailing-list').addClass('has-errors email-error');
			$('.message').text('Enter an email address');
		} else if (!pattern.test(data.email_address)) {
			$('.mailing-list').addClass('has-errors email-error');
			$('.message').text('Enter a valid email address');
		} else {
			$('.mailing-list').removeClass('has-errors');
			button.addClass('thinking');

			axios.post(`${window.location.href}mcsubscribe`, data).then((response) => {
				console.log(response);
				if (response.status === 200) {
					showSuccess();
				}
			}).catch((error) => {
				console.log(error);
			});
		}
	});
});
